exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktualnosci-tsx": () => import("./../../../src/pages/aktualnosci.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-tsx" */),
  "component---src-pages-de-about-tsx": () => import("./../../../src/pages/de/about.tsx" /* webpackChunkName: "component---src-pages-de-about-tsx" */),
  "component---src-pages-de-coaches-tsx": () => import("./../../../src/pages/de/coaches.tsx" /* webpackChunkName: "component---src-pages-de-coaches-tsx" */),
  "component---src-pages-de-tsx": () => import("./../../../src/pages/de.tsx" /* webpackChunkName: "component---src-pages-de-tsx" */),
  "component---src-pages-en-about-tsx": () => import("./../../../src/pages/en/about.tsx" /* webpackChunkName: "component---src-pages-en-about-tsx" */),
  "component---src-pages-en-coaches-tsx": () => import("./../../../src/pages/en/coaches.tsx" /* webpackChunkName: "component---src-pages-en-coaches-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-gra-tsx": () => import("./../../../src/pages/gra.tsx" /* webpackChunkName: "component---src-pages-gra-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-projekty-tsx": () => import("./../../../src/pages/projekty.tsx" /* webpackChunkName: "component---src-pages-projekty-tsx" */),
  "component---src-pages-ru-about-tsx": () => import("./../../../src/pages/ru/about.tsx" /* webpackChunkName: "component---src-pages-ru-about-tsx" */),
  "component---src-pages-ru-coaches-tsx": () => import("./../../../src/pages/ru/coaches.tsx" /* webpackChunkName: "component---src-pages-ru-coaches-tsx" */),
  "component---src-pages-ru-tsx": () => import("./../../../src/pages/ru.tsx" /* webpackChunkName: "component---src-pages-ru-tsx" */),
  "component---src-pages-summit-tsx": () => import("./../../../src/pages/summit.tsx" /* webpackChunkName: "component---src-pages-summit-tsx" */),
  "component---src-pages-trenerzy-tsx": () => import("./../../../src/pages/trenerzy.tsx" /* webpackChunkName: "component---src-pages-trenerzy-tsx" */),
  "component---src-pages-ukr-about-tsx": () => import("./../../../src/pages/ukr/about.tsx" /* webpackChunkName: "component---src-pages-ukr-about-tsx" */),
  "component---src-pages-ukr-coaches-tsx": () => import("./../../../src/pages/ukr/coaches.tsx" /* webpackChunkName: "component---src-pages-ukr-coaches-tsx" */),
  "component---src-pages-ukr-tsx": () => import("./../../../src/pages/ukr.tsx" /* webpackChunkName: "component---src-pages-ukr-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */)
}

